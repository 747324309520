.btn{
  border-radius: 25px;
  margin-bottom: 10px;
  margin-left: 5px;
}
#marquee {
	position:absolute;
	left:0;
	top:-10px;
	width:100%;
	height:8px;
	z-index:1;
}
  .btnWrapper{
    display: flex;
    flex-direction: row;;
    gap: 20px;
    align-items: flex-start;
    justify-content: flex-start;
}

body {
  margin: 0;
  padding: 0;
}

.popupBackground {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.popupContent {
  background-color: #fff;
  margin:15px;
  padding: 30px;
  border-radius: 2px;
  box-shadow: 0px 0px 10px #000;
}

.button {
  font: inherit;
  border: 1px solid #577ec8;
  background: #6a86bc;
  color: white;
  padding: 0.35rem 1.5rem;
  cursor: pointer;
  font-size: 1.15rem;
  border-radius: 30px;
}

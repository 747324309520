body {
    margin: 0;
    padding: 0;
  }
  
  .popupBackground {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
  }
  
  .popupContent {
    background-color: #fff;
    width: 80%;
    max-width: 450px;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px #000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  
  .popupBody {
    padding: 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .button {
    font: inherit;
    border: 1px solid #577ec8;
    background: #6a86bc;
    color: white;
    padding: 0.35rem 1.5rem;
    cursor: pointer;
    font-size: 1.15rem;
    border-radius: 30px;
  }

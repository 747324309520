.box {
    border: 5px;
    border-style: solid;
    border-radius: 15px;
    padding: 25px;
    border-color: #ffffff;
    box-shadow: 3px 3px 8px 0px #0a0a0a;
    background-color: aliceblue;
}
.logobox{
    width: 100%;
    height: auto;
    }

    